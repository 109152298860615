//@ts-nocheck
// Customizable Area Start
import React from "react";
import { OutlinedInput, Paper, Button, IconButton, Card, InputAdornment, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Container, } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { ArrowDown, uploadImage, arrowRight, togleFalse, togleTrue, wright, imageFalse, cross, ArrowUp, leftAArrow, info_message, hexogon, } from "./assets";
import "../assets/Styles/editProfile.css";
import EditBusinessProfileController from "./EditBusinessProfileController.web";
import CountryCodeModal from "../../../blocks/social-media-account-login/src/CountryCodeModal.web";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import { locationIcon, success, } from "../../CustomisableUserProfiles/src/assets";
import { close_modal_icon } from "../../appointmentmanagement/src/assets";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
import Loader from "../../../components/src/Loader.web";
// Customizable Area End
class EditBusinessProfile extends EditBusinessProfileController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const { workingHoursError, weekendHoursError } = this.state;
        const borderStyleworkingHours = this.getBorderStyle(workingHoursError);
        const borderStyleweekendHours = this.getBorderStyle(weekendHoursError);
        let onlyArabicRegex = /^$|^[\u0600-\u06FF\s]+$/;
        //@ts-ignore
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let editBusinessDropDownClassName = webAppDirection === "ltr" ? "dropdown-image" : "arabic-dropdown-image";
        // Customizable Area Start
        // Customizable Area End
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { className: "profile-page-main-container" },
                React.createElement("div", { style: {
                        marginBottom: "40px",
                    } },
                    React.createElement(AfterLoginHeader, { navigation: this.props.navigation })),
                React.createElement(Container, { maxWidth: "lg" },
                    React.createElement(Grid, { container: true, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                                React.createElement("img", { id: "back", src: leftAArrow, onClick: this.handleBack, alt: "back", height: "25px", width: "25px", style: {
                                        cursor: "pointer",
                                        transform: webAppDirection === "rtl" ? "scaleX(-1)" : "scaleX(1)",
                                    } }),
                                React.createElement("div", { className: "profile-header-main-item-text" }, t("Business Profile"))))),
                    React.createElement(Grid, { container: true, spacing: 6 },
                        React.createElement(Grid, { container: true, item: true, xs: 12, md: 6, style: { height: "fit-content" } },
                            this.state.crnUpdateStatus == "pending" && (React.createElement(Card, { className: "note-card-details-style" },
                                React.createElement("img", { src: hexogon, height: 20, width: 20, alt: "pending" }),
                                React.createElement("span", null, t("Please note CRN will be updated post admins approval.")))),
                            this.state.crnUpdateStatus == "approved" && (React.createElement(Card, { className: "note-card-details-style" },
                                React.createElement("img", { src: success, height: 20, width: 20, alt: "approved" }),
                                React.createElement("span", null, t("Your request has been approved by admin.")))),
                            React.createElement(Card, { className: "card-details-style" },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Business pictures(5 max)")),
                                    React.createElement(Card, { style: {
                                            backgroundColor: this.getCardBackGroundColor(this.state.showImages),
                                        }, className: "profile-root-style-card" },
                                        React.createElement("div", null,
                                            React.createElement(Grid, { container: true, spacing: 3, style: { overflow: "hidden" }, alignItems: "center", justifyContent: "center" },
                                                React.createElement(Grid, { container: true, spacing: this.state.businessImages.length ? 2 : 0, item: true, xs: 12, style: { height: "100%" } },
                                                    React.createElement(ImageUploading, { multiple: true, onChange: this.onChange, value: this.state.businessImages, acceptType: ["jpg", "jpeg", "png"], dataURLKey: "data_url" }, ({ imageList, onImageUpload, onImageRemove, isDragging, dragProps, }) => (React.createElement(React.Fragment, null, !this.state.showImages ? (React.createElement(Grid, { item: true, xs: 12, style: {
                                                            textAlign: "center",
                                                            height: "100%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            borderRadius: 10,
                                                        } },
                                                        React.createElement(IconButton, Object.assign({ style: isDragging
                                                                ? { color: "red" }
                                                                : undefined, onClick: onImageUpload }, dragProps),
                                                            React.createElement("img", { src: uploadImage, alt: "" })))) : (React.createElement(React.Fragment, null,
                                                        React.createElement(Grid, { item: true, xs: 4 },
                                                            React.createElement("div", { style: { position: "relative" } },
                                                                React.createElement("img", { src: imageList[0].data_url, alt: "", width: "100%", height: "280px", style: {
                                                                        borderRadius: 10,
                                                                        objectFit: "cover",
                                                                    } }),
                                                                React.createElement(IconButton, { onClick: () => onImageRemove(0), style: {
                                                                        position: "absolute",
                                                                        right: 0,
                                                                        top: 0,
                                                                    } },
                                                                    React.createElement("img", { src: imageFalse, onClick: () => {
                                                                            this.getImageid(imageList[0].index);
                                                                        }, width: "30", height: "30" })))),
                                                        React.createElement(Grid, { container: true, spacing: 2, item: true, xs: 8 },
                                                            imageList
                                                                .slice(1)
                                                                .map((item, index) => (React.createElement(Grid, { item: true, xs: 6 },
                                                                React.createElement("div", { style: { position: "relative" } },
                                                                    React.createElement("img", { src: item.data_url, alt: "", width: "100%", height: "130px", style: {
                                                                            borderRadius: 10,
                                                                            objectFit: "cover",
                                                                        } }),
                                                                    React.createElement(IconButton, { onClick: () => onImageRemove(index + 1), style: {
                                                                            position: "absolute",
                                                                            right: 0,
                                                                            top: 0,
                                                                        } },
                                                                        React.createElement("img", { onClick: () => {
                                                                                this.getImageid(item.index);
                                                                            }, src: imageFalse, alt: "", width: "30", height: "30" })))))),
                                                            imageList.length < 5 && (React.createElement(Grid, { item: true, xs: 6, style: {
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    display: "flex",
                                                                    height: "150px",
                                                                } },
                                                                React.createElement(IconButton
                                                                // style={isDragging ? { color: "red" } : undefined}
                                                                , Object.assign({ 
                                                                    // style={isDragging ? { color: "red" } : undefined}
                                                                    onClick: onImageUpload }, dragProps),
                                                                    React.createElement("img", { src: uploadImage })))))))))))))),
                                    React.createElement("div", { style: {
                                            color: "#757575",
                                            fontSize: "12px",
                                            marginTop: "15px",
                                            width: "100%",
                                            display: "flex",
                                        } }, t("Dimension: 1240 X 280 - Horizontal and HD images are preferable")),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.businessImagesError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Business name *")),
                                    React.createElement(OutlinedInput, { fullWidth: true, type: "text", value: this.state.businessName, id: "txtInputBusinessname", className: "form-input-style", error: this.state.businessNameError, onChange: (e) => this.setInputValue(e.target.value), inputProps: {
                                            min: 0,
                                            maxLength: 50,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, onBlur: (e) => this.validateBusinessName() }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.businessNameError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { style: { color: "#2C2C2E" }, className: "form-label-profile" }, t("Business name arabic")),
                                    React.createElement(OutlinedInput, { id: "txtInputArabicBusinessname", type: "text", fullWidth: true, value: this.state.arabicBusinessName, className: "form-input-style", onChange: (e) => {
                                            if (/^$|^[\u0600-\u065F\s]+$/.test(e.target.value)) {
                                                this.setArabicInput(e.target.value);
                                            }
                                        }, 
                                        // error={this.state.arabicBusinessNameError}
                                        // onBlur={(e) => this.validateArabicBusinessName()}
                                        inputProps: {
                                            style: { outline: "none", boxShadow: "none" },
                                            min: 0,
                                            maxLength: 50,
                                        } })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Owner name *")),
                                    React.createElement(OutlinedInput, { fullWidth: true, type: "text", id: "ownername", value: this.state.businessOwner, error: this.state.businessOwnerError, onChange: (e) => {
                                            if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                                                this.setState({
                                                    businessOwnerError: "",
                                                    businessOwner: e.target.value,
                                                });
                                            }
                                        }, className: "form-input-style", inputProps: {
                                            min: 0,
                                            maxLength: 50,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, onBlur: (e) => this.validateBusinessOwner() }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.businessOwnerError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Business email *")),
                                    React.createElement(OutlinedInput, { fullWidth: true, type: "text", id: "businessEmail", disabled: true, value: this.state.businessEmail, error: this.state.businessEmailError, onChange: (e) => {
                                            if (/^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/.test(e.target.value)) {
                                                this.setState({
                                                    businessEmailError: "",
                                                    businessEmail: e.target.value,
                                                });
                                            }
                                        }, className: "form-input-style", placeholder: "growyourbusiness@example.com", inputProps: {
                                            min: 0,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, onBlur: (e) => this.validateBusinessEmail() }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.businessEmailError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Website (Optional)")),
                                    React.createElement(OutlinedInput, { fullWidth: true, error: this.state.websiteError, value: this.state.website, onChange: (e) => {
                                            if (/^[a-zA-Z0-9-./:]*$/.test(e.target.value)) {
                                                this.setState({
                                                    websiteError: "",
                                                    website: e.target.value,
                                                });
                                            }
                                        }, type: "text", id: "website", className: "form-input-style", placeholder: "www.example.com", inputProps: {
                                            min: 0,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, onBlur: (e) => this.validateWebsite() }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.websiteError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Contact Number *")),
                                    React.createElement(OutlinedInput, { fullWidth: true, error: this.state.contactError, disabled: true, onChange: this.handleContactChange, value: this.state.contact, type: "number", id: "phoneNumber", className: "form-input-style-contact-number", name: "phoneNumber", inputProps: {
                                            min: 0,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, onBlur: (e) => this.validateContact(), startAdornment: React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(IconButton, { style: {
                                                    width: 66,
                                                    height: 48,
                                                    borderRight: webAppDirection === "ltr"
                                                        ? "1px solid #979797"
                                                        : "",
                                                    borderLeft: webAppDirection === "rtl"
                                                        ? "1px solid #979797"
                                                        : "",
                                                    cursor: "pointer",
                                                    justifyContent: "center",
                                                    borderRadius: "0%",
                                                }, disabled: true, id: "countrycode", onClick: () => this.handleCountryCodeChange() },
                                                React.createElement("span", { className: "country-code-text-style", style: {
                                                        color: "#2c2c2e",
                                                    } }, this.state.countryCode))) }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.contactError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("CRN *")),
                                    React.createElement(OutlinedInput, { fullWidth: true, error: this.state.crnError, onChange: (e) => {
                                            if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                                this.setState({ crnError: "", crn: e.target.value });
                                            }
                                        }, value: this.state.crn, type: "text", id: "crn", className: "form-input-style", inputProps: {
                                            min: 0,
                                            maxLength: 10,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, onBlur: (e) => this.validateCRN() }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.crnError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Service provided")),
                                    React.createElement("div", { className: "form-select-input-style-profile" },
                                        React.createElement("div", { className: "custom-select-profile" },
                                            React.createElement("select", { disabled: true, id: "outlined-select", onChange: (event) => {
                                                    this.setServiceID(event.target.value);
                                                }, onClick: () => {
                                                    this.handleSelectToggle("isSelectOpen");
                                                }, style: { direction: "inherit" } }, this.state.providedService.map((option) => (React.createElement("option", { style: {
                                                    fontFamily: "Poppins-Medium, sans-serif",
                                                }, key: option.id, value: option.id }, t(option.name))))),
                                            React.createElement("img", { src: this.state.isSelectOpen ? ArrowUp : ArrowDown, alt: "Custom Icon", className: editBusinessDropDownClassName })))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("About us (Optional)")),
                                    React.createElement(OutlinedInput, { fullWidth: true, id: "aboutMultiline", className: "form-input-style", value: this.state.aboutUsText, style: {
                                            border: "1px solid #757575",
                                            marginTop: "20px",
                                            height: "150px",
                                            fontFamily: "Poppins, sans-serif",
                                            flexDirection: "column",
                                        }, multiline: true, rows: 4, onChange: (e) => {
                                            if (/^[a-zA-Z .]*$/.test(e.target.value)) {
                                                this.setState({ aboutUsText: e.target.value });
                                            }
                                        }, placeholder: t("Write about your business…"), type: "text", inputProps: {
                                            min: 0,
                                            maxLength: 250,
                                            style: {
                                                marginLeft: 5,
                                                outline: "none",
                                                boxShadow: "none",
                                            },
                                        } })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { style: { color: "#2C2C2E" }, className: "form-label-profile" }, t("About us (Optional) arabic")),
                                    React.createElement(OutlinedInput, { className: "form-input-style", id: "aboutArabicMultiline", fullWidth: true, value: this.state.aboutUsTextArabic, style: {
                                            height: "150px",
                                            marginTop: "20px",
                                            flexDirection: "column",
                                            border: "1px solid #757575",
                                            fontFamily: "Poppins, sans-serif",
                                        }, rows: 4, multiline: true, onChange: (e) => {
                                            if (/^$|^[\u0600-\u065F\s.]+$/.test(e.target.value)) {
                                                this.setState({ aboutUsTextArabic: e.target.value });
                                            }
                                        }, type: "text", placeholder: t("Write about your business in arabic…"), inputProps: {
                                            maxLength: 250,
                                            min: 0,
                                            style: {
                                                marginLeft: 5,
                                                outline: "none",
                                                boxShadow: "none",
                                            },
                                        } }))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "profile-header-main-item-text", style: { margin: "30px 0" } }, t("Business location"))),
                            React.createElement(Card, { className: "card-details-style" },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Where’s your business located?")),
                                    React.createElement(OutlinedInput, { fullWidth: true, type: "text", id: "location", disabled: true, onClick: () => {
                                            this.setState({ openLocationDialog: true });
                                        }, placeholder: t("Location"), inputProps: { min: 0 }, className: "form-input-style", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                            React.createElement(IconButton, null,
                                                React.createElement("img", { src: arrowRight, alt: "logo", width: "24px", height: "24px", className: "img_2-icon" }))) }),
                                    React.createElement("div", { style: {
                                            marginLeft: 50,
                                            marginTop: 15,
                                        } },
                                        React.createElement("span", { className: "profile-error-message-text" }))))),
                        React.createElement(Grid, { container: true, item: true, xs: 12, md: 6, style: { height: "fit-content" } },
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "profile-header-main-item-text", style: { marginBottom: 30 } }, t("Payment")),
                                React.createElement(Card, { className: "card-details-style" },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Bank account *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", id: "add-bank-account", value: this.state.bankName, readOnly: true, onClick: () => {
                                                this.setStateValue();
                                            }, error: this.state.bankDetailsError, placeholder: t("Add bank details"), inputProps: { min: 0 }, className: "form-input-style", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                React.createElement(IconButton, null,
                                                    React.createElement("img", { src: arrowRight, className: "img_2-icon", alt: "logo", width: "24px", height: "24px" }))) }),
                                        React.createElement("div", { style: {
                                                marginLeft: 50,
                                                marginTop: 15,
                                            } },
                                            React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.bankDetailsError))))),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E", marginTop: 40 } }, t("Payment options")),
                                        React.createElement("div", { style: {
                                                marginTop: 10,
                                                display: "flex",
                                                opacity: "0.6",
                                            } },
                                            React.createElement(Checkbox, { id: "payatshop", name: "PayAtShop", color: "primary", disabled: true, checked: this.state.enablePayAtShop, className: "terms-checkbox-input" }),
                                            React.createElement("span", { className: "payment-options-lable" }, t("Pay at shop(card or cash)"))),
                                        React.createElement("div", { style: { opacity: "0.6" } },
                                            React.createElement(Checkbox, { checked: this.state.enableCardPayment, name: "CardPayment", color: "primary", className: "cardpayemnt", disabled: true }),
                                            React.createElement("span", { className: "payment-options-lable" }, t("Card payment"))),
                                        React.createElement("div", null,
                                            React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.paymentOptionError))),
                                            React.createElement("span", { className: "profile-message-text" },
                                                React.createElement("img", { src: info_message, alt: "info_message" }),
                                                t("Please reach out to SqueezMeIn to edit this option")))))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "profile-header-main-item-text", style: { margin: "30px 0" } }, t("What’s your team size?")),
                                React.createElement(Card, { className: "card-details-style" },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Add the number of employees")),
                                        this.state.teamSizeData.map((item, teamIndex) => {
                                            return (React.createElement("div", { key: teamIndex, style: { marginTop: 10 } },
                                                React.createElement(Checkbox, { checked: this.state.selectedTeamSize ===
                                                        item.attributes.id, id: "payatshop", name: "PayAtShop", color: "primary", onChange: () => {
                                                        this.handleSelection(item.attributes.id);
                                                    } }),
                                                React.createElement("span", { className: "terms-checkbox-input" },
                                                    " ",
                                                    t(item.attributes.name))));
                                        }),
                                        this.state.selectedTeamSizeError && (React.createElement("span", { style: { marginLeft: "10px" }, className: "location-error-message-text" }, t(this.state.selectedTeamSizeError)))))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "profile-header-main-item-text", style: { margin: "30px 0" } }, t("Business working hours")),
                                React.createElement(Card, { className: "card-details-style" },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("div", { className: "input-container-left-edit-profile" },
                                            React.createElement("div", { className: "input-container-button-edit-profile" },
                                                React.createElement(ToggleButtonGroup, null,
                                                    React.createElement(ToggleButton, { onClick: () => {
                                                            this.setState({ getSelectionMode: "weekdays" });
                                                        }, style: {
                                                            width: 150,
                                                            borderRadius: 24,
                                                            height: 44,
                                                            border: 0,
                                                            fontWeight: 500,
                                                            fontSize: 14,
                                                            textTransform: "none",
                                                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays"),
                                                            color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                                                        }, className: "weekdays", value: "left", id: "btnSelect", "aria-label": "left aligned" },
                                                        React.createElement("span", { className: "fontfamily-profile" }, t("Weekdays"))),
                                                    React.createElement(ToggleButton, { onClick: () => {
                                                            this.setState({ getSelectionMode: "weekends" });
                                                        }, style: {
                                                            width: 150,
                                                            border: 0,
                                                            borderRadius: 24,
                                                            height: 44,
                                                            textTransform: "none",
                                                            fontWeight: 500,
                                                            fontSize: 14,
                                                            fontFamily: "Poppins",
                                                            color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                                                        }, value: "center", "aria-label": "centered", id: "weekEnds" },
                                                        React.createElement("span", { className: "fontfamily-profile" }, t("Weekends"))))),
                                            this.state.getSelectionMode === "weekdays" ? (React.createElement(Grid, { container: true, spacing: 3 },
                                                React.createElement(Grid, { item: true, xs: 6 },
                                                    React.createElement("div", { className: "profile-form-sub-header", style: { marginTop: 30, marginBottom: 15 } }, t("From *")),
                                                    React.createElement("div", { className: "custom-select-profile" },
                                                        React.createElement("select", { id: "SelectWeekHours", onClick: () => {
                                                                this.handleSelectToggle("isSelectOpenWeekHoursFrom");
                                                            }, onChange: (event) => {
                                                                this.onWorkingFromSelection(event.target.value);
                                                            }, value: this.state.workingFrom, style: {
                                                                borderRadius: 8,
                                                                border: borderStyleworkingHours,
                                                                direction: "inherit",
                                                            } }, this.state.workingSlot.map((option, count) => {
                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                            return (React.createElement("option", { key: count, disabled: this.optionDisable(option), id: count, value: option },
                                                                time,
                                                                " ",
                                                                t(amPm)));
                                                        })),
                                                        React.createElement("img", { src: this.state.isSelectOpenWeekHoursFrom
                                                                ? ArrowUp
                                                                : ArrowDown, alt: "Custom Icon", className: editBusinessDropDownClassName }))),
                                                React.createElement(Grid, { item: true, xs: 6 },
                                                    React.createElement("div", { className: "profile-form-sub-header", style: { marginTop: 30, marginBottom: 15 } }, t("To *")),
                                                    React.createElement("div", { className: "custom-select-profile" },
                                                        React.createElement("select", { value: this.state.workingTo, id: "mySelectTo", onChange: (event) => {
                                                                this.setState({
                                                                    workingTo: event.target.value,
                                                                    workingHoursError: "",
                                                                });
                                                            }, onClick: () => {
                                                                this.handleSelectToggle("isSelectOpenWeekHoursTo");
                                                            }, disabled: this.state.isWorkingToDisable, style: {
                                                                borderRadius: 8,
                                                                border: borderStyleworkingHours,
                                                                direction: "inherit",
                                                            } }, this.state.workingSlotTo.map((option, count) => {
                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                            return (React.createElement("option", { key: count, id: count, disabled: this.optionDisable(option), value: option },
                                                                time,
                                                                " ",
                                                                t(amPm)));
                                                        })),
                                                        React.createElement("img", { src: this.state.isSelectOpenWeekHoursTo
                                                                ? ArrowUp
                                                                : ArrowDown, className: editBusinessDropDownClassName, alt: "Custom Icon" }))))) : (React.createElement(Grid, { container: true, spacing: 3 },
                                                React.createElement(Grid, { item: true, xs: 6 },
                                                    React.createElement("div", { className: "profile-form-sub-header", style: { marginTop: 30, marginBottom: 15 } }, t("From *")),
                                                    React.createElement("div", { className: "custom-select-profile" },
                                                        React.createElement("select", { onChange: (event) => {
                                                                this.onWeekendFromSelection(event.target.value);
                                                            }, id: "mySelect", style: {
                                                                borderRadius: 8,
                                                                border: borderStyleweekendHours,
                                                                direction: "inherit",
                                                            }, value: this.state.weekendFrom, onClick: () => {
                                                                this.handleSelectToggle("isSelectOpenWeekEndsFrom");
                                                            } }, this.state.workingSlot.map((option, count) => {
                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                            return (React.createElement("option", { disabled: this.optionDisable(option), id: count, value: option, key: count },
                                                                time,
                                                                " ",
                                                                t(amPm)));
                                                        })),
                                                        React.createElement("img", { className: editBusinessDropDownClassName, src: this.state.isSelectOpenWeekEndsFrom
                                                                ? ArrowUp
                                                                : ArrowDown, alt: "Custom Icon" }))),
                                                React.createElement(Grid, { item: true, xs: 6 },
                                                    React.createElement("div", { className: "profile-form-sub-header", style: { marginTop: 30, marginBottom: 15 } }, t("To *")),
                                                    React.createElement("div", { className: "custom-select-profile" },
                                                        React.createElement("select", { id: "mySelect", disabled: this.state.isWeekendToDisable, value: this.state.weekendTo, onClick: () => {
                                                                this.handleSelectToggle("isSelectOpenWeekEndsTo");
                                                            }, onChange: (event) => {
                                                                this.setState({
                                                                    weekendTo: event.target.value,
                                                                    weekendHoursError: "",
                                                                });
                                                            }, style: {
                                                                borderRadius: 8,
                                                                border: borderStyleweekendHours,
                                                                direction: "inherit",
                                                            } }, this.state.weekendSlotTo.map((option, count) => {
                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                            return (React.createElement("option", { disabled: this.optionDisable(option), key: count, id: count, value: option },
                                                                time,
                                                                " ",
                                                                t(amPm)));
                                                        })),
                                                        React.createElement("img", { src: this.state.isSelectOpenWeekEndsTo
                                                                ? ArrowUp
                                                                : ArrowDown, className: editBusinessDropDownClassName, alt: "Custom Icon" }))))),
                                            React.createElement("div", { className: "input-container-item-time" },
                                                this.state.workingHoursError && (React.createElement("div", { style: {
                                                        marginLeft: 60,
                                                        marginBottom: 10,
                                                        position: "relative",
                                                    } },
                                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.state.workingHoursError)))),
                                                this.state.weekendHoursError && (React.createElement("div", { style: {
                                                        position: "relative",
                                                        marginBottom: 10,
                                                        marginLeft: 60,
                                                    } },
                                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.state.weekendHoursError))))),
                                            React.createElement("div", { className: "", style: { margin: "20px 0" } },
                                                React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Working Days"))),
                                            React.createElement(Grid, { container: true, spacing: 2 }, this.state.workingDaysData.map((item) => {
                                                return (React.createElement(Grid, { item: true, xs: 4, key: item.id },
                                                    React.createElement(Button, { fullWidth: true, onClick: () => {
                                                            this.handleWorkingHoursSelection(item.id);
                                                        }, style: {
                                                            padding: 0,
                                                            border: 0,
                                                            background: 0,
                                                            borderRadius: "27px",
                                                            height: "54px",
                                                            fontSize: "18px",
                                                            backgroundColor: this.handleBackgroundColor(item),
                                                            color: this.handleColor(item),
                                                            textTransform: "capitalize",
                                                        }, className: "button-text", color: "primary" },
                                                        React.createElement("span", { className: "fontfamily-profile" }, t(item.day)))));
                                            })),
                                            React.createElement("div", { style: { marginLeft: 60, marginTop: 20 } }, React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessage())))))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "profile-header-main-item-text", style: { margin: "30px 0" } }, t("Team members")),
                                React.createElement(Card, { className: "card-details-style" },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("div", { className: "team-icon-edit-profile" },
                                            React.createElement("div", { className: "switch-icon" },
                                                React.createElement("input", { onChange: () => {
                                                        this.setState({
                                                            allowTeam: !this.state.allowTeam,
                                                        });
                                                    }, type: "checkbox", checked: this.state.allowTeam, className: "react-switch-checkbox", id: "react-switch-new" }),
                                                React.createElement("label", { htmlFor: "react-switch-new", className: "react-switch-label" },
                                                    React.createElement("img", { src: this.getTogle(this.state.allowTeam, togleTrue, togleFalse), alt: "logo", height: "24px", width: "42px" }))),
                                            React.createElement("div", { className: "team-members" },
                                                React.createElement("span", { style: { fontSize: "18px" }, className: "form-label-team" }, t("Show staff members to the customers")),
                                                React.createElement("span", { style: { color: "#757575", fontSize: 14 }, className: "form-label-team" }, t("Allow the customer to make the bookings with a team member."))))))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "" },
                                    React.createElement("div", null,
                                        React.createElement(Button, { fullWidth: true, color: "primary", className: "edit-profile-next-Step-button", onClick: () => {
                                                this.validateBusinessSetup();
                                            } }, t("Edit profile")),
                                        React.createElement("div", { style: {
                                                marginLeft: 10,
                                                marginTop: 20,
                                                position: "absolute",
                                            } },
                                            React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.submisionError))))))))),
                    this.state.countryCodeModal && (React.createElement(CountryCodeModal, { open: this.state.countryCodeModal, onSelect: this.handleCountryCodeChange })),
                    React.createElement(Dialog, { PaperProps: {
                            style: {
                                width: 491,
                                height: 700,
                                borderRadius: "24px",
                                boxShadow: "none",
                            },
                        }, open: this.state.openBankDetailsDialog },
                        React.createElement("div", { style: {
                                display: "flex",
                                justifyContent: "flex-end",
                                cursor: "pointer",
                            } },
                            React.createElement("img", { src: close_modal_icon, className: "img-debit-1", alt: "", onClick: () => {
                                    this.setState({ openBankDetailsDialog: false });
                                } })),
                        React.createElement(DialogTitle, { style: { textAlign: "center", padding: 0 }, id: "simple-dialog-title" },
                            React.createElement("span", { className: "dialog-label", style: { fontSize: "20px", lineHeight: "24px" } }, t("Add Bank Details"))),
                        React.createElement(DialogContent, null,
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Full name")),
                                React.createElement(OutlinedInput, { error: this.state.accountOwnerNameError, disabled: true, onChange: (e) => this.setState({
                                        accountOwnerNameError: "",
                                        accountOwnerFullName: e.target.value,
                                    }), type: "text", id: "accountOwnerFullName", className: "dialog-input-style", inputProps: { min: 0, style: { marginLeft: 5 } }, onBlur: () => this.validateName(), value: this.state.accountOwnerFullName }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.accountOwnerNameError))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Bank name")),
                                React.createElement(OutlinedInput, { disabled: true, error: this.state.bankNameError, value: this.state.bankName, onChange: (e) => this.setState({
                                        bankNameError: "",
                                        bankName: e.target.value,
                                    }), type: "text", id: "bankname", className: "dialog-input-style", inputProps: { min: 0, style: { marginLeft: 5 } }, onBlur: () => this.validateBankName() }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.bankNameError))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Bank account number")),
                                React.createElement(OutlinedInput, { disabled: true, error: this.state.accountNumberError, value: this.state.accountNumber, onChange: (e) => this.setState({
                                        accountNumberError: "",
                                        accountNumber: e.target.value,
                                    }), type: "text", id: "accountNumber", className: "dialog-input-style", inputProps: {
                                        min: 0,
                                        maxLength: 16,
                                        style: { marginLeft: 5 },
                                    }, onBlur: () => this.validateAccountNumber() }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.accountNumberError))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Confirm bank account number")),
                                React.createElement(OutlinedInput, { disabled: true, error: this.state.confirmAccountNumberError, value: this.state.confirmAccountNumber, id: "confirmAccountNumber", onChange: (e) => this.setState({
                                        confirmAccountNumberError: "",
                                        confirmAccountNumber: e.target.value,
                                    }), type: "text", className: "dialog-input-style", inputProps: {
                                        min: 0,
                                        maxLength: 16,
                                        style: { marginLeft: 5 },
                                    }, onBlur: () => this.validateConfirmAccountNumber() }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.confirmAccountNumberError))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Purpose of payment")),
                                React.createElement(OutlinedInput, { disabled: true, id: "purposeofpayment", error: this.state.purposeOfPaymentError, value: this.state.purposeOfPayment, onChange: (e) => this.setState({
                                        purposeOfPaymentError: "",
                                        purposeOfPayment: e.target.value,
                                    }), type: "text", className: "dialog-input-style", inputProps: { min: 0, style: { marginLeft: 5 } }, onBlur: () => this.validatePurpose() }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.purposeOfPaymentError))))),
                    React.createElement(Dialog, { PaperProps: {
                            style: {
                                width: "100%",
                                height: 700,
                                borderRadius: "24px",
                                boxShadow: "none",
                            },
                        }, open: this.state.openLocationDialog },
                        React.createElement(DialogTitle, { style: { textAlign: "center", marginTop: 20 }, id: "simple-dialog-title" },
                            React.createElement("span", { className: "dialog-label", style: { fontSize: "20px", lineHeight: "24px" } }, t("Business location"))),
                        React.createElement(DialogContent, null,
                            React.createElement(Paper, { style: {
                                    border: 0,
                                    borderTop: 0,
                                    borderRadius: 0,
                                    backgroundColor: "#FFFFFF",
                                    marginTop: 40,
                                }, variant: "outlined" },
                                React.createElement("div", null,
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label" }, t("Where’s your business located?")),
                                        React.createElement(OutlinedInput, { type: "text", fullWidth: true, id: "txtbusinesslocation", style: {
                                                marginTop: 30,
                                                height: "48px",
                                                border: "1px solid #757575",
                                                fontFamily: "Poppins-Medium, sans-serif",
                                            }, startAdornment: React.createElement(InputAdornment, { position: "start" },
                                                React.createElement(IconButton, { style: {
                                                        width: 56,
                                                        height: 48,
                                                        borderRight: webAppDirection === "ltr"
                                                            ? "1px solid #979797"
                                                            : "",
                                                        borderLeft: webAppDirection === "rtl"
                                                            ? "1px solid #979797"
                                                            : "",
                                                        cursor: "pointer",
                                                        borderRadius: "0%",
                                                    }, onClick: () => {
                                                        this.getLocation();
                                                    } },
                                                    React.createElement("img", { src: locationIcon, alt: "type", width: "20px", height: "20px" }))), value: this.state.businessLocation, inputProps: { min: 0, style: { marginLeft: 10 } }, error: this.state.businessLocationError, onChange: (e) => this.setState({
                                                businessLocationError: "",
                                                businessLocation: e.target.value,
                                            }) }),
                                        this.state.businessLocationError && (React.createElement("span", { className: "location-error-message-text" }, t("Please enter the location.")))),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Address *")),
                                        React.createElement(OutlinedInput, { type: "text", className: "form-input-style", fullWidth: true, error: this.state.addressError, value: this.state.address, id: "address", onChange: (e) => this.setState({
                                                addressError: "",
                                                address: e.target.value,
                                            }), onBlur: () => this.setState({
                                                addressError: this.validateField("address"),
                                            }) }),
                                        this.state.addressError && (React.createElement("span", { className: "location-error-message-text" }, t("Please enter the address.")))),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Landmark (Optional)")),
                                        React.createElement(OutlinedInput, { type: "text", className: "form-input-style", fullWidth: true, error: this.state.landmarkError, value: this.state.landmark, id: "landmark", onChange: (e) => this.setState({
                                                landmarkError: "",
                                                landmark: e.target.value,
                                            }) }),
                                        this.state.landmarkError && (React.createElement("span", { className: "location-error-message-text" }, t("Please enter the landmark.")))),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Postal code *")),
                                        React.createElement(OutlinedInput, { type: "text", className: "form-input-style", fullWidth: true, error: this.state.postalCodeError, value: this.state.postalCode, id: "postalCode", onChange: (e) => this.setState({
                                                postalCodeError: "",
                                                postalCode: e.target.value,
                                            }), onBlur: () => this.setState({
                                                postalCodeError: this.validateField("postalCode"),
                                            }) }),
                                        this.state.postalCodeError && (React.createElement("span", { className: "location-error-message-text" }, t("Please enter the Postal code.")))),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("City *")),
                                        React.createElement(OutlinedInput, { type: "text", className: "form-input-style", fullWidth: true, error: this.state.cityNameError, id: "cityName", value: this.state.cityName, onChange: (e) => this.setState({
                                                cityNameError: "",
                                                cityName: e.target.value,
                                            }), onBlur: () => this.setState({
                                                cityNameError: this.validateField("cityName"),
                                            }) }),
                                        this.state.cityNameError && (React.createElement("span", { className: "location-error-message-text" }, t("Please enter the city.")))),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("State *")),
                                        React.createElement(OutlinedInput, { type: "text", className: "form-input-style", fullWidth: true, error: this.state.stateNameError, value: this.state.stateName, id: "stateName", onChange: (e) => this.setState({
                                                stateNameError: "",
                                                stateName: e.target.value,
                                            }), onBlur: () => this.setState({
                                                stateNameError: this.validateField("stateName"),
                                            }) }),
                                        this.state.stateNameError && (React.createElement("span", { className: "location-error-message-text" }, t("Please enter the state.")))),
                                    React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: 40 } },
                                        React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Country *")),
                                        React.createElement(OutlinedInput, { type: "text", className: "form-input-style", fullWidth: true, error: this.state.countryNameError, value: this.state.countryName, id: "countryName", onChange: (e) => this.setState({
                                                countryNameError: "",
                                                countryName: e.target.value,
                                            }), onBlur: () => this.setState({
                                                countryNameError: this.validateField("countryName"),
                                            }) }),
                                        this.state.countryNameError && (React.createElement("span", { className: "location-error-message-text" }, t("Please enter the country."))))))),
                        React.createElement(DialogActions, { style: { padding: 25 } },
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 6 },
                                    React.createElement(Button, { className: "dialog-button", onClick: this.cancelLocationPopup, id: "cancel-btn-location", variant: "outlined" }, t("Cancel"))),
                                React.createElement(Grid, { item: true, xs: 6 },
                                    React.createElement(Button, { style: {
                                            backgroundColor: "#1e5fea",
                                            color: "#ffffff",
                                        }, className: "dialog-button", onClick: () => {
                                            this.validate();
                                        }, id: "save-btn-location", variant: "outlined" }, t("Save")))))),
                    React.createElement(Dialog, { open: this.state.openAddedDetailsDialog, PaperProps: {
                            style: {
                                width: 410,
                                height: 345,
                                borderRadius: "24px",
                                boxShadow: "none",
                            },
                        } },
                        React.createElement("span", { style: { textAlign: "right", marginRight: 30, marginTop: 30 } },
                            React.createElement(IconButton, { color: "primary", "aria-label": "upload picture", component: "span", id: "close-dialog", onClick: () => {
                                    this.setState({ openAddedDetailsDialog: false });
                                } },
                                React.createElement("img", { src: cross, alt: "logo", width: "24px", height: "24px" }))),
                        React.createElement("div", { style: {
                                marginTop: 20,
                                marginLeft: 150,
                                marginBottom: 20,
                                marginRight: 50,
                            } },
                            React.createElement("img", { src: wright, alt: "logo", width: "120px", height: "120px" })),
                        React.createElement("span", { style: {
                                textAlign: "center",
                                fontSize: 20,
                                fontFamily: "Poppins-Medium, sans-serif",
                            } }, t("Your account details")),
                        React.createElement("span", { style: {
                                textAlign: "center",
                                fontSize: 20,
                                fontFamily: "Poppins-Medium, sans-serif",
                            } }, t("is added successfully!")))))));
    }
}
export default withTranslation()(EditBusinessProfile);
